import React from "react"
import { LazyLoadComponent } from "react-lazy-load-image-component"

const BuildSection = () => (
  <section
    style={{
      background: "#fff",
      padding: "3rem 0",
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <h2>Build Affordable Enterprise-Level Applications</h2>
          <p className="mt-4">
            Traditional application development is slow and expensive.
            Out-of-the-box solutions help reduce those costs, but they limit
            your business. That's not a formula for success. With Quickbase, you
            can finally build the business your customers need instead of
            letting your technology dictate where you go.
          </p>
          <p>
            As the leading low code platform, Quickbase empowers you to develop
            affordable custom applications faster than traditional software
            development. Whether you need to automate invoicing, streamline
            customer relationship management, or connect your entire business,
            Quickbase gives you the tools you need to build a more efficient
            business. It's time.
          </p>
          <p>
            Unleash enterprise-level results without the enterprise-level price
            tag.
          </p>
        </div>
        <div className="col-lg-6 align-self-center pt-3">
          <LazyLoadComponent>
            <iframe
              width="100%"
              height="380px"
              src="https://www.youtube.com/embed/qOwsx0wtniM"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullscreen
              title="Tech Consulting"
              className="rounded"
            ></iframe>
          </LazyLoadComponent>
        </div>
      </div>
    </div>
  </section>
)

export default BuildSection
